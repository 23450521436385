import router from 'next/router'
import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { toggleCart } from '../redux/minicart.slice';
import Link from 'next/link'
import parse from 'html-react-parser'
import Image from 'next/image'
import Script from 'next/script'

import HeaderMenu from './menu/headermenu'
import TopMenu from './menu/topmenu'

function Header({ menu, config }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);

  const [autosearch_state, set_autosearch_state] = useState({ loaded: false })
  const [show_mobile_menu, set_show_mobile_menu] = useState(false)
  const [show_mobile_search, set_show_mobile_search] = useState(false)
  const [path, set_path] = useState('/')

  const handleBackClick = () => {
    router.back();
  };

  const toggle_mobile_menu = () => {
    if (show_mobile_menu) {
      set_show_mobile_menu(false)
    } else {
      set_show_mobile_menu(true)
    }
  }

  const toggle_mobile_search = () => {
    if (show_mobile_search) {
      set_show_mobile_search(false)
    } else {
      set_show_mobile_search(true)
    }

    if(show_mobile_menu){
      set_show_mobile_menu(false)
    }
  }

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

  // Function to update the screen size status
  const updateScreenSize = () => {
    setIsWideScreen(window.innerWidth >= 1024);
  };

  // Add an event listener for window resize
  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  const iconStyle = {
    display: isWideScreen ? 'none' : 'block',
  };

  var token = false
  var merchant = false
  if (user[0] != undefined) {
    token = user[0].token
    if (user[0].roles != undefined && user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
      merchant = true
    }
  }

  const get_cart_item = () => {
    return cart.reduce(
      (accumulator, item) => accumulator + item.quantity,
      0
    );
  };

  const toggle_cart = () => {
    dispatch(toggleCart())
  }

  const enter_search = (event) => {
    set_autosearch_state({ loaded: false })
    event.preventDefault()
    const data = new FormData(event.target);
    const form = Object.fromEntries(data.entries())
    if (form.search.length >= 3) {
      router.push({
        pathname: '/search',
        query: { search: form.search },
      }, undefined, { shallow: true });
    }
  }

  const submit_search = (query) => {
    set_autosearch_state({ loaded: false })
    router.push({
      pathname: '/search',
      query: { search: query },
    }, undefined, { shallow: true });
  }

  const get_header_menu = (menu) => {
    // function to take all the menus and only extract the header one for rendering here
    if (menu) {
      var result = menu.filter(obj => {
        return obj.slug == 'header'
      })
      return result[0]
    }
  }

  const get_brand_menu = (menu) => {
    // function to take all the menus and only extract the header one for rendering here
    if (menu) {
      var result = menu.filter(obj => {
        return obj.slug == 'brandmenu'
      })
      return result[0]
    }
  }

  const get_top_menu = (menu) => {
    // function to take all the menus and only extract the header one for rendering here
    if (menu) {
      var result = menu.filter(obj => {
        return obj.slug == 'top'
      })
      return result[0]
    }
  }

  const contactEmailLink = "mailto:" + config.email;
  const contactPhoneLink = "tel:" + config.phone;

  const search_api_call = async (query) => {
    var endpoint = process.env.NEXT_PUBLIC_API_URL + '/autocomplete'
    var headers = {
      'Content-Type': 'application/json'
    }

    if (merchant) {
      headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      }
      endpoint = process.env.NEXT_PUBLIC_API_URL + '/merchant-autocomplete'
    }
    fetch(
      endpoint,
      {
        body: JSON.stringify({
          search: (query) ? (query) : 'search',
        }),
        headers: headers,
        method: 'POST'
      }
    )
      .then(response => {
        // reject not ok response
        if (!response.ok) {
          return Promise.reject(response)
        }
        return response.json() // or return response.text()
      })
      .catch(async response => {
        const error = await response.json().then(text => text)
        return Promise.reject(error)
      })
      .then(data => {
        if (data.data.data.length == 0) {
          set_autosearch_state({
            loaded: false,
          })
        } else {
          set_autosearch_state({
            loaded: true,
            query: query,
            options: data.data.data,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  const submit_async_search = event => {
    if (event.target.value.length >= 8) {
      // turn off the brands menu, merchant menu and regular menu
      search_api_call(event.target.value)
    } else {
      set_autosearch_state({
        loaded: false,
      })
    }
  };
  useEffect(() => {
    "use strict";
    router.events.on("routeChangeComplete", (path) => {
    if(!path.includes("/checkout") && !path.includes("/login")){
      // setTimeout(() => {
      //   richSnippetReviewsWidgets("text-banner-widget", {
      //     store: "www.advancedwater.co.uk",
      //     starsClr: "#105E76",
      //     textClr: "#002E3E",
      //     logoClr: "black",
      //     widgetName: "text-banner",
      //     css: '.TextBanner span { font-size: 16px; } ' +
      //       '.ruk_word { display:none !important} ' +
      //       '.TextBanner { margin:0 !important; font-family: "Montserrat", sans-serif !important; text-align: left; display: block;} ' +
      //       '.TextBanner .TextBanner__text{ font-weight: 500 !important; } ' +
      //       '.TextBanner .TextBanner__text--outof .ruk_count { font-size: 16px !important; } ' +
      //       '.TextBanner .TextBanner__text--outof .ruk_count { font-size: 16px !important; } ' +
      //       '.TextBanner .TextBanner__text--outof .ruk_rating { font-size: 16px !important;} ' +
      //       '.TextBanner .TextBanner__stars.RatingStars { float: left; margin: 4px; display: inline;} ' +
      //       '.noBreakWrap { display: inline !important; margin: 0 !important;} ' +
      //       '.ruk_customers_say { display:none !important} ' +
      //       '.icon-percentage-star--100 RatingStars__star { font-size: 16px!important} ' +
      //       '.TextBanner .ReviewsLogo { display:none !important} ' +
      //       '.TextBanner .TextBanner__stars {font-size: 32px !important; }' +
      //       '.TextBanner .TextBanner__stars i.ruk-icon-percentage-star-75-01 { font-size: 26px !important; top: -4px;}' +
      //       '.ReviewsLogo--small .ReviewsLogo__star i { font-size: 16px !important;} ' +
      //       '.ReviewsLogo--small .ReviewsLogo__text i { font-size: 16px; margin-left: 4px;}',
      //   })
      // }, 500);
    }
      });
 
      set_path(router.pathname)
      console.log(router.pathname)
      return () => {
        router.events.off('routeChangeComplete', 0);
    }
  }, [router]);
  return (
    <>
      {(config.top_page_banner_enabled == "true") ? (
        <div className="top-banner bg-brand-tertiary">
          <p>{(config.top_page_banner_text) ? (parse(config.top_page_banner_text)):('')}</p>
        </div>
      ):('')}
      <div className="bg-white site-information text-xs py-2 border-b border-gray-100 hidden lg:block relative z-40">
        <div className="container container-2xl mx-auto flex flex-row justify-between relative bg-white">
          <div className="contact-details flex flex-row">
            <p className="mr-6 text-brand-tertiary font-bold"><strong>Email us:</strong>  <a href={contactEmailLink} className="text-brand-primary font-medium hover:underline">{config.email}</a></p>
            <p className="text-brand-tertiary font-bold"><strong>Call us:</strong>  <a href={contactPhoneLink} className="text-brand-primary font-medium hover:underline">{config.phone}</a></p>
          </div>

          <div className="helpful-links">
            <TopMenu menu={get_top_menu(menu)}></TopMenu>
          </div>
        </div>
      </div>
      <header className="bg-white border-b-4 border-brand-tertiary lg:border-none sticky top-0 z-30">
        <div className="brand-bar lg:py-8 relative bg-white z-20">
          <div className="flex items-center justify-between relative lg:container container-2xl mx-auto">
            <div className='flex items-center'>
            {(path != '/' && path != '/index-mobile' ? 
              <button onClick={handleBackClick}>
                <a className="fas fa-arrow-left text-3xl text-brand-primary hover:text-brand-tertiary ml-2" style={iconStyle}></a>
              </button>
              : '' )}
              <Link href="/">
                <a className="logo h-8 lg:h-10 my-6 ml-5 lg:my-0 lg:ml-2 flex items-center" title="Go to homepage">
                  <Image
                    src={process.env.NEXT_PUBLIC_LOGO_IMAGE}
                    alt={config.company_name}
                    width="200"
                    height="40"
                    className=""
                    priority
                    placeholder="blur"

                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
                </a>
              </Link>
            </div>
            <form
              className="hidden lg:flex flex-grow search mx-16 h-12"
              onSubmit={(event) => enter_search(event)}
            >
              <input
                onKeyUp={(event) => submit_async_search(event)}
                type="text"
                name="search"
                placeholder="Search by item name, code or brand &hellip;"
                className="flex w-full h-full rounded-tl rounded-bl placeholder-gray-500 leading-none text-gray-800 p-4 border border-gray-300 hover:border-brand-secondary active:border-brand-tertiary transition-all focus:outline-none focus:border-brand-tertiary focus:ring-2 focus:ring-inset focus:ring-brand-tertiary"
              />
              <button aria-label="Search" type="submit" className="btn btn-primary btn-searchbar h-full">
                <i className="icon icon-search w-4 h-4"></i>
              </button>
            </form>
            <div className="flex flex-row mr-3 lg:lr-0">
              <div className="hidden lg:flex">
                {(token) ? (
                  <>
                    <Link href="/account"><a className="btn btn-md btn-secondary mr-4">My Account</a></Link>
                    <Link href="/logout"><a className="btn btn-md btn-secondary mr-5">Logout</a></Link>
                  </>
                ) : (
                  <>
                    <Link href="/login?create=true"><a className="btn btn-md btn-secondary mr-4">Create Account</a></Link>
                    <Link href="/login"><a className=" btn btn-md btn-secondary mr-5">Login</a></Link>
                  </>
                )}
              </div>
              <button type="button" onClick={() => toggle_mobile_search()} className="flex items-center justify-center lg:hidden self-center h-12 w-12" title="Search">
                {(show_mobile_search) ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g>
                      <line x1="4" x2="20" y1="4" y2="20" fill="none" stroke="#105e76" strokeLinecap="round" strokeWidth="2" />
                      <line x1="20" x2="4" y1="4" y2="20" fill="none" stroke="#105e76" strokeLinecap="round" strokeWidth="2" />
                    </g>
                  </svg>
                ) : (
                  <svg className="max-h-full" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 24">
                    <g transform="translate(-245 -29)">
                      <path d="M23,23l-6.667-6.667m2.222-5.556A7.778,7.778,0,1,1,10.778,3,7.778,7.778,0,0,1,18.556,10.778Z" transform="translate(245 27)" fill="none" stroke="#002e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </g>
                  </svg>
                )}
              </button>
              <button type="button" onClick={() => toggle_cart()} className="group flex items-center justify-center self-center relative h-12 w-12" title="My Trolley">
                <svg className="max-h-full lg:h-8 stroke-current text-brand-tertiary group-hover:text-brand-secondary w-6 h-6 lg:w-8 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
                  <path d="M3,3H5.222l.444,2.222m1.778,8.889H18.556L23,5.222H5.667m1.778,8.889L5.667,5.222m1.778,8.889L4.9,16.659a1.111,1.111,0,0,0,.786,1.9H18.556m0,0a2.222,2.222,0,1,0,2.222,2.222A2.222,2.222,0,0,0,18.556,18.556ZM9.667,20.778a2.222,2.222,0,1,1-2.222-2.222A2.222,2.222,0,0,1,9.667,20.778Z" transform="translate(-2 -2)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
                <span className="absolute top-0 right-0 h-5 w-5 text-white leading-none text-xs text-center font-semibold flex items-center justify-center uppercase rounded-full bg-rose-600 last:mr-0 mr-1">
                  {get_cart_item()}
                </span>
              </button>
              <button
                type="button"
                className="px-3 lg:hidden self-center"
                title="Navigation"
                onClick={() => toggle_mobile_menu()}
              >
                {(show_mobile_menu) ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g>
                      <line x1="4" x2="20" y1="4" y2="20" fill="none" stroke="#105e76" strokeLinecap="round" strokeWidth="2" />
                      <line x1="20" x2="4" y1="4" y2="20" fill="none" stroke="#105e76" strokeLinecap="round" strokeWidth="2" />
                    </g>
                  </svg>
                ) : (
                  <svg className="max-h-full" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g>
                      <line x1="1" y1="4" x2="23" y2="4" fill="none" stroke="#002e3e" strokeLinecap="round" strokeWidth="2" />
                      <line x1="1" y1="12" x2="23" y2="12" fill="none" stroke="#002e3e" strokeLinecap="round" strokeWidth="2" />
                      <line x1="1" y1="20" x2="23" y2="20" fill="none" stroke="#002e3e" strokeLinecap="round" strokeWidth="2" />
                    </g>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <HeaderMenu menu={get_header_menu(menu)} brandMenu={get_brand_menu(menu)} top_menu_tree={get_top_menu(menu)} show_mobile_menu={show_mobile_menu} autosearch={autosearch_state}></HeaderMenu>

        {(show_mobile_search) ? (
          <form
            className="lg:hidden flex flex-grow search mx-5 h-12 mb-5"
            onSubmit={(event) => enter_search(event)}
          >
            <input
              // onKeyUp={(event) => submit_async_search(event)}
              type="text"
              name="search"
              placeholder="Search products and spares"
              className="flex w-full h-full rounded-tl rounded-bl placeholder-gray-500 leading-none text-gray-800 p-4 border border-gray-300 hover:border-brand-secondary active:border-brand-tertiary transition-all focus:outline-none focus:border-brand-tertiary focus:ring-2 focus:ring-inset focus:ring-brand-tertiary"
              required
            />
            <button type="submit" className="btn btn-primary btn-searchbar h-full flex items-center">
              <i className="icon icon-search w-4 h-4"></i>
            </button>
          </form>
        ) : ('')}

      </header>
      <div className="delivery-and-rating py-2 bg-gray-200 hidden lg:block">
        <div className="container container-2xl mx-auto text-xs tracking-wide uppercase flex flex-row justify-center font-bold text-brand-tertiary items-center">
          <span className="mr-2">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><path d="M13,16V6a1,1,0,0,0-1-1H4A1,1,0,0,0,3,6V16a1,1,0,0,0,1,1H5m8-1a1,1,0,0,1-1,1H9m4-1V8a1,1,0,0,1,1-1h2.586a1,1,0,0,1,.707.293l3.414,3.414a1,1,0,0,1,.293.707V16a1,1,0,0,1-1,1H19m-6-1a1,1,0,0,0,1,1h1M5,17a2,2,0,0,0,4,0M5,17a2,2,0,0,1,4,0m6,0a2,2,0,0,0,4,0m-4,0a2,2,0,0,1,4,0" transform="translate(-2.3 -4.3)" fill="none" stroke="#002e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" /></svg> */}
            {(config?.header_banner_text_1_image_url ? 
              <Image
                  src={config?.header_banner_text_1_image_url}
                  alt={config.company_name}
                  width="20"
                  height="16"
                  className=""
                  priority
                  placeholder="blur"
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                />
                :'')}
            
          </span>
          <Link href={config?.header_banner_text_1_url || '#'} passHref><p className='cursor-pointer m-0-important'>{config.header_banner_text_1}</p></Link>
          <span className="ml-16 mr-2">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><path d="M13,16V6a1,1,0,0,0-1-1H4A1,1,0,0,0,3,6V16a1,1,0,0,0,1,1H5m8-1a1,1,0,0,1-1,1H9m4-1V8a1,1,0,0,1,1-1h2.586a1,1,0,0,1,.707.293l3.414,3.414a1,1,0,0,1,.293.707V16a1,1,0,0,1-1,1H19m-6-1a1,1,0,0,0,1,1h1M5,17a2,2,0,0,0,4,0M5,17a2,2,0,0,1,4,0m6,0a2,2,0,0,0,4,0m-4,0a2,2,0,0,1,4,0" transform="translate(-2.3 -4.3)" fill="none" stroke="#002e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" /></svg> */}
            {(config?.header_banner_text_2_image_url ? 
              <Image
                  src={config?.header_banner_text_2_image_url}
                  alt={config.company_name}
                  width="20"
                  height="16"
                  className=""
                  priority
                  placeholder="blur"
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                />
                :'')}
          </span>
          <Link href={config?.header_banner_text_2_url || '#'} passHref><p className='cursor-pointer m-0-important'>{config.header_banner_text_2}</p></Link>
          <span className="ml-16 mr-2">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><path d="M13,16V6a1,1,0,0,0-1-1H4A1,1,0,0,0,3,6V16a1,1,0,0,0,1,1H5m8-1a1,1,0,0,1-1,1H9m4-1V8a1,1,0,0,1,1-1h2.586a1,1,0,0,1,.707.293l3.414,3.414a1,1,0,0,1,.293.707V16a1,1,0,0,1-1,1H19m-6-1a1,1,0,0,0,1,1h1M5,17a2,2,0,0,0,4,0M5,17a2,2,0,0,1,4,0m6,0a2,2,0,0,0,4,0m-4,0a2,2,0,0,1,4,0" transform="translate(-2.3 -4.3)" fill="none" stroke="#002e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" /></svg> */}
            {(config?.header_banner_text_3_image_url ? 
              <Image
                  src={config?.header_banner_text_3_image_url}
                  alt={config.company_name}
                  width="20"
                  height="16"
                  className=""
                  priority
                  placeholder="blur"
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                />
                :'')}
          </span>
          <Link href={config?.header_banner_text_3_url || '#'} passHref><p className='cursor-pointer m-0-important'>{config.header_banner_text_3}</p></Link>
          {/* <div className="ml-16" id="text-banner-widget"></div> */}
          {/* <Script
            id="reviews-widget"
            src="https://widget.reviews.co.uk/rich-snippet-reviews-widgets/dist.js"
            onLoad={() =>
              richSnippetReviewsWidgets("text-banner-widget", {
                store: "www.advancedwater.co.uk",
                starsClr: "#105E76",
                textClr: "#002E3E",
                logoClr: "black",
                widgetName: "text-banner",
                css: '.TextBanner span { font-size: 16px; } ' +
                  '.ruk_word { display:none !important} ' +
                  '.TextBanner { margin:0 !important; font-family: "Montserrat", sans-serif !important; text-align: left; display: block;} ' +
                  '.TextBanner .TextBanner__text{ font-weight: 500 !important; } ' +
                  '.TextBanner .TextBanner__text--outof .ruk_count { font-size: 16px !important; } ' +
                  '.TextBanner .TextBanner__text--outof .ruk_count { font-size: 16px !important; } ' +
                  '.TextBanner .TextBanner__text--outof .ruk_rating { font-size: 16px !important;} ' +
                  '.TextBanner .TextBanner__stars.RatingStars { float: left; margin: 4px; display: inline;} ' +
                  '.noBreakWrap { display: inline !important; margin: 0 !important;} ' +
                  '.ruk_customers_say { display:none !important} ' +
                  '.icon-percentage-star--100 RatingStars__star { font-size: 16px!important} ' +
                  '.TextBanner .ReviewsLogo { display:none !important} ' +
                  '.TextBanner .TextBanner__stars {font-size: 32px !important; }' +
                  '.TextBanner .TextBanner__stars i.ruk-icon-percentage-star-75-01 { font-size: 26px !important; top: -4px;}' +
                  '.ReviewsLogo--small .ReviewsLogo__star i { font-size: 16px !important;} ' +
                  '.ReviewsLogo--small .ReviewsLogo__text i { font-size: 16px; margin-left: 4px;}',
              })
            }
          >
          </Script> */}

        </div>
      </div>
    </>
  );
}

export default Header;